<template>
  <div class="g_page_box box">
    <g-loading :loading="loading" />
    <div :class="['detail','g_main_content']" >
      <div class="head">
        <div class="bg"/>
        <div class="text1">
          订单详情
          <div>详情如下</div>
        </div>
      </div>
      <div class="flow" v-if="false">
        <div class="flow-info">
          物流信息
          <span>{{list.length>0 ? list[0].state_label : ''}}</span>
        </div>
        <div class="phone">中通快递 7202039939391</div>
        <ul class="flow-content">
          <li>
            <div class="line"/>
            <div class="icon">收</div>
            <div class="message">
              上海浦东新区东方路1800弄
              <div class="time">李明  18621566608</div>
            </div>
          </li>
          <li v-for="(i,index) in list" :key="index">
            <template v-if="index<1 || show">
              <div class="line" v-if="(show&&index !== list.length-1)"/>
              <div class="icon"><van-icon name="success" style="font-weight: bold;font-size: 18px"/></div>
              <div class="message">
                {{i.state_label}}
                <div class="time">{{i.time}}</div>
              </div>
            </template>
          </li>
        </ul>
        <div class="more" @click="look_more">点击查看更多物流详情</div>
      </div>
      <div class="content">
         <!-- 等待检验检查流程提示 -->
        <template>
          <div class="title">请至{{detail_data.org_name}}做相关{{item_type}}检查/检验</div>
            <div class="part-1" v-if="detail_data.org_name">
              <Map @distance="set_distance" :name="detail_data.org_name" :default_loation="detail_data" :geolocation="geolocation"/>
            </div>
            <div class="location-label" v-if="detail_data.org_name">
              <div class="location-text" @click="getMapApp">
                <img width="16" height="16" src="../../assets/img/order/location.png"/>
                <div class="label">
                  {{detail_data.org_name}}
                  <div>{{detail_data.org_adress}}</div>
                </div>
              </div>
              距离{{distance/1000}}km
            </div>
        </template>
      </div>
       <!-- 等待检验检查流程提示 -->
      <div v-if="info_arr.length>0" class="info">
        <div class="title-2">检查须知</div>
        <div class="part-3" v-for="(i,index) in info_arr" :key="index">
          {{index+1}}. {{i.content}}
        </div>
      </div>
      <div class="prescriptions">
        <div class="title-2">处方单</div>
        <ul class="part-2">
          <li v-for="i in order_arr" :key="i.item_id" class="lab-i">
            <div class="name">
              {{i.title}}
              <div>{{i.desc}}</div>
            </div>
            <div class="res_item_right" v-if="i.price">¥ {{ format_money(i.price) }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import{mapState,mapActions} from 'vuex'
import {get_order,get_info} from './service'
import {format_date,format_money} from '../../utils/format'
import Map from './Map'
export default {
  data () {
    return {
      order_id: '',
      loading: false,
      item_type: '',
      state: null,
      active: 0,
      order_arr: [],
      name: '',
      detail_data: {},
      distance: 0,
      info_arr: [],
      show: false,
      list: [
        {
          time: '09-17 11:26',
          state_label: '已签收',
        },
        {
          time: '09-17 11:26',
          state_label: '运输中',
        },
        {
          time: '09-17 11:26',
          state_label: '已经发货',
        }
      ]
    }
  },
  computed:{
    timestamp() {
      return this.format_date(this.now_date)
    },
    ...mapState('commonData', {
      geolocation: state => state.geolocation
    }),
  },
  components: {
    Map
  },
  watch: {
    onConfirm(date) {
      this.show = false;
      this.date = date;
    },
    now_date(val) {
      let time = this.format_date(val)
      this.date_obj[time] = this.organizations
      this.order_obj = this.date_obj[time][0]
      this.$nextTick(()=>{
        this.$refs.scroll.scrollLeft = 0
      })
    },
  },
  created() {
    let {order_id} = this.$route.query
    this.order_id = order_id
    this.search_detail()
  },
  methods: {
    ...mapActions('commonData', [
      'get_geolocation'
    ]),
    format_date,
    format_money,
    set_distance(val) {
      this.distance = val
    },
    getMapApp() {
      const { latitude, longitude, org_adress} = this.detail_data
      let url = 'https://uri.amap.com/marker?position='+ longitude + ',' + latitude +'&name='+ org_adress +'&callnative=1';
      window.location.href = url
    },
    look_more() {
      this.show = !this.show
    },
    async search_detail () {
      try {
        this.loading = true
        const {data} = await get_order({order_id:this.order_id})
        this.search_info([data.inspect_code])
        this.detail_data = data
        this.order_arr = data.items
      } finally  {
        this.loading = false
      }
    },
    async search_info (ids) {
      try {
        this.loading = true
        const {data} = await get_info({ids})
        let arr = []
        for(let i of Object.values(data)) {
          for(let item of i) {
            if(item.categeory === '检查前须知' || item.categeory === '注意事项') {
              arr.push(item)
            }
          }
        }
        this.info_arr = arr
      } finally  {
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
.detail {
  padding-bottom: 50px;
  background: #f6f6f6;
}
.head {
  padding: 25px 23px 24px 23px;
  height: 100px;
  position: relative;
  background: #000CA8;
  display: flex;
  flex-direction: column;
  & .text1 {
    flex:1;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    color: #FFFFFF;
    & >div {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #FFFFFF;
    }
  }
  & .bg {
    width: 106px;
    height: 100%;
    background: url('../../assets/img/order/detail-bg.png') center center no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.content {
  padding: 20px  20px 30px;
  margin-top: 20px;
  background: #fff;
  & .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #242938;
    padding-bottom: 14px;
  }
  & .part-1 {
    height: 130px;
    background: rgba(0, 0, 0, 0);
    border:1px solid #ddd;
  }
  & .location-label {
    padding-top: 16px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    color: #8B959E;
    padding-bottom: 12px;
    & .location-text {
      flex: 1;
      display: flex;
      & >img {
        margin-right: 9px;

      }
      & .label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #242938;
        & >div{
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }

}
.title-2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #242938;
  padding: 0px 0 9px;
}
.info {
  background: #fff;
  margin-top: 20px;
  padding: 20px;
   & .part-3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #242938;
  }
}
.prescriptions{
  background: #fff;
  margin-top: 20px;
  padding: 20px;
}
.part-2 {
  & .lab-i {
    padding: 14px 17px;
    min-height: 65px;
    background: rgba(231, 241, 253, 0.44);
    border-radius: 10px;
    margin-bottom:14px;
    display: flex;
    align-items: center;
    & .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #242938;
        flex:1;
      & >div {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        color: #454545;
      }
    }
    & .state {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #0088FF;
      padding-left: 15px;
      & .finish-state {
        color: rgba(9, 187, 7, 1);
      }
    }
  }
}
.flow {
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  & .phone {
    padding: 18px 0;
    font-size: 14px;
    line-height: 16px;
    color: #262626
  }
  & .flow-info{
    display: flex;
    justify-content:space-between;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #565656;
    & >span {
      font-size: 18px;
      font-weight: 500;
      line-height: 16px;
      color: #0088FF;
    }
  }
  & .flow-content{
    & >li {
      display: flex;
      position: relative;
      & .line {
        position: absolute;
        border-left: 2px dotted #0088FF;
        top: 27px;
        left: 12px;
        height: 40%;
      }
      & .icon {
        width: 24px;
        height: 24px;
        background: #0088FF;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        position: relative;
        z-index: 1;
      }
      & .message {
        font-size: 14px;;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 18px;
        color: #262626;
        & .time {
          font-size: 12px;;
          font-weight: 400;
          line-height: 19px;
          color: #565656;
        }
      }
    }
  }
  & .more {
    font-size: 14px;
    line-height: 16px;
    color: #0088FF;
    text-align: center;
  }
}
</style>
